<template>
  <v-container id="urban_renewal-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('owner.title')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-btn color="aqua" class="mx-2" @click="back()">{{
          $t("common.back")
        }}</v-btn>
        <v-btn color="primary" dark class="mx-2" @click="getOwners()">
          <v-icon left>mdi-file-export</v-icon>
          {{ $t("owner.exportData") }}
        </v-btn>
        <v-dialog
          v-model="dialog_form"
          max-width="500px"
          @click:outside="close"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mx-2" v-on="on">
              <v-icon left>mdi-account-edit</v-icon>
              {{ $t("owner.importOwners") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("owner.importOwners") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-select
                          :items="importTypes"
                          :rules="fieldRules"
                          :label="$t('owner.importTypes')"
                          v-model="item.importTypes"
                        >
                        </v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" md="5">
                        <a :href="publicPath+'static/計票系統匯入-範例.xlsx'" download>
                          <v-btn color="primary" dark class="mx-2">
                            <v-icon left>mdi-file-chart</v-icon>{{ $t('owner.downloadSampleFile') }}
                          </v-btn>
                        </a>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          v-model="uploadFile"
                          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          :label="$t('common.importFile')"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="sendFile">
                {{ $t("common.import") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="primary" dark class="mx-2" @click="createOwner(urban)">
          <v-icon left>mdi-pencil-plus</v-icon>
          {{ $t("owner.createOwner") }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.owner_name="{ item }">
          <span v-if="item.owner_name">
            {{ item.owner_name }}
          </span>
          <span v-else>
            {{ item.owner_name_auto }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item, urban)"
              >{{ $t("common.profile") }}</v-btn
            >
            <v-btn color="red" class="mx-2" small
            @click="RemoveOwner(item)"
            >{{
              $t("common.delete")
            }}</v-btn>
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CommonUtil from "@/utils/commonutil.js";
export default {
  data: (vm) => ({
    publicPath: process.env.BASE_URL,
    dialog_message: false,
    urban: { name: null },
    items: [],
    building_infos:[],
    land_infos:[],
    loading: false,
    dialog_form: false,
    options: {},
    totalItems: 0,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    message: {
      title: "",
      text: ""
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("owner.regno_no_list"),
        value: "regno_no",
      },
      {
        sortable: false,
        text: vm.$i18n.t("owner.owner_name"),
        value: "owner_name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land.land_area"),
        value: "land_area",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building.building_area"),
        value: "building_area",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    item: {
      owner_name: null,
      owner_name_auto: null,
      urban_renewal: null,
      phone: null,
      importTypes: null,
    },
    uploadFile: null,
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
    importTypes: [
      {
        value: 1,
        text: vm.$i18n.t("owner.newImport"),
      },
      {
        value: 2,
        text: vm.$i18n.t("owner.updateImport"),
      },
    ],
    valid: true,
  }),
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/owner/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
            page: page,
            page_size: itemsPerPage,
            urban_renewal: this.urban
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results.owners;
      this.building_infos = data.results.building_relation;
      this.land_infos = data.results.land_relation;
      this.common_part = data.results.common_part
      this.items.forEach(element=>{
        this.setBuildingInfo(element)
        this.setLandInfo(element)
      })
    },
    setBuildingInfo(item){
      item.building_area = 0
      this.building_infos.forEach(element=>{
        if(element.owner == item.id){
          item.building_area += (parseFloat(element.building_info.building_area) * element.building_numerator / element.building_denominator)
          this.common_part.forEach(common_part=>{
            if(common_part.building_info.id == element.building_info.id){
              item.building_area += ((parseFloat(common_part.building_area) * common_part.building_numerator / common_part.building_denominator) * element.building_numerator / element.building_denominator)
            }
          })
        }
      })
      item.building_area = item.building_area.toFixed(2)
    },
    setLandInfo(item){
      item.land_area = 0
      this.land_infos.forEach(element=>{
        if(element.owner == item.id){
          item.land_area += (parseFloat(element.land_info.land_area) * element.land_numerator / element.land_denominator)
        }
      })
      item.land_area = item.land_area.toFixed(2)
    },
    showDetail(item, urban) {
      this.$router.push({
        name: "OwnerDetail",
        query: {
          item: item.id,
          urban: urban,
        },
      });
    },
    createOwner(urban) {
      this.$router.push({
        name: "OwnerDetail",
        query: {
          item: null,
          urban: urban,
        },
      });
    },
    sendFile() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/owner/uploadFile/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let formData = new FormData();
      formData.append("urban_renewal", this.urban);
      formData.append("import_type", this.item.importTypes);
      formData.append("uploadFile", this.uploadFile);
      this.axios
        .post(url, formData, config)
        .then(function (response) {
          currentObj.loading = false;
          if (response.data.status != 1) {
            alert("創建失敗，請檢查上傳檔案格式/內容");
            return;
          }
          alert("送出成功");
          currentObj.close();
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    RemoveOwner(item){
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
        )
      ) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/owner/" +
        item.id +
        "/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      this.axios
        .patch(url, {}, config)
        .then(function(response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    back(item) {
      this.$router.push({
        name: "UrbanRenewalTables",
        params: {
          // item: item
        },
      });
    },
    hasArea(item){
      let valid = false;
      if(item.building_info && item.building_numerator && item.building_denominator){
        valid = true;
      } else if (item.land_info && item.land_numerator && item.land_denominator){
        valid = true;
      }
      return valid
    },
    getOwners(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/urban/" +
        this.urban +
        "/getOwnerData/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: 'arraybuffer'
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          let date_str = CommonUtil.getDateString();
          let file_name = currentObj.urban_name + "所有權人_"+ date_str +".xlsx"
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          currentObj.selectedItem = null;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
          currentObj.selectedItem = null;
        });
    }
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.OwnerList"));
    this.urban = this.$route.query.item;
    this.urban_name = this.$route.query.urban_name;
    // this.getApi();
  }
};
</script>
<style lang='sass' scope>
.v-form
  width: 100%
  .v-btn__content
    a
      color: #FFFFFF
      text-decoration: none

</style>